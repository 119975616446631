body {
  margin: 0;
  padding: 0;
  font-family: "Unbounded", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body,
#root,
.App {
  background-color: #222529;
}

html {
  overscroll-behavior: none;
}

.App {
  margin: 0;
  padding: 0;
}
.container-button {
  display: flex;
}

.project-title,
.contact-title {
  margin-bottom: 2rem;
}
.card-text {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 1rem;
}
.card-body {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 0;
}


.card:hover {
  border: 2px solid gray;
}

.form-btn {
  margin-top: 1rem;
}

.label {
  margin: 0.5rem;
}
.nav-link:hover {
  transform: scale(1.1);
}

#about {
  background-size: cover;
}

/* FLIP */
.flip-card {
  background-color: transparent;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  /* text-align: center; */
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-card-back {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-image: url("./assets/astro.jpg");
  /* background-size: cover; */

  padding: 1rem;
  border-radius: 0.5rem;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #bbb;
  color: black;
}

/* Style the back side */
.flip-card-back {
  color: white;
  transform: rotateY(180deg);
}

/* 
  TOOGLE
} */

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
@keyframes jump {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

.hover-icon {
  animation: jump 4s ease-in-out infinite;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fe9a35;

  background-size: cover;

  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 32px;
  width: 32px;
  background-image: url("./assets/sun.png");
  background-size: cover;

  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: black;
}

input:focus + .slider {
  /* box-shadow: 0 0 1px #7263a1; */
  box-shadow: 0 0 1px #fea73e;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-image: url("./assets/moon.png");
  background-size: cover;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.dark-toggle-button {
  display: flex;
  align-items: center;
}

/*?
  TOOGLE
} */

.btn .btn-secondary {
  background-color: #4e3596 !important;
}

#about {
  margin-top: 6rem;
}

.btn {
  background-color: #4e3596 !important;
}

.light {
  background-color: #fff;
  color: #333;
  /* transition: background-color 1s ease, color 1s ease; */
}

.dark {
  background-color: #222529;
  position: relative;

  color: #edeef3 !important;
  /* transition: background-color 1s ease, coloar 1s ease; */
  --bs-body-bg: #222529;
  --bs-card-border-width: 0 !important;
  --bs-body-color: #edeef3;
}

.dark::before {
  content: "";
  background-image: url("./assets/astro.jpg");
  background-size: cover;
  transition: opacity 0.5s ease-in-out;
  max-height: 20%;
  width: inherit;
  top: 10vh;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  opacity: 0.3;
  pointer-events: none;
}

.astro {
  width: 100%;
}
.nav-link {
  font-size: large !important;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  flex-wrap: wrap;
  background-color: #4e3596;
}

.footer h3 {
  padding: 1rem;
  color: white;
}

#project,
.contact {
  margin-top: 2rem;
}

/* .g-4,
.gy-4 {
  --bs-gutter-y: 10.5rem;
} */

/* MEDIAAAA */
/*? SMALL SCREEN */

@media (max-width: 768px) {
  .dark::before {
    max-height: 11%;
  }
}
/*? SCREEN */

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .row-cols-md-4 > * {
    width: 50% !important;
  }
  .dark::before {
    max-height: 13%;
  }
 
}

/*? BIG SCREEN */
@media only screen and (min-width: 1025px) {
 
}
